import React from 'react'
import Logo from "./Logo_Horizontal.png";
// import { RiArrowDropDownLine } from "react-icons/ri";
import {Link} from 'react-router-dom';
import './style.css';
import { RxHamburgerMenu } from "react-icons/rx";
import { useState } from 'react';

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    return(
        <>
<div class='Logoportion'>

   <Link to="/" class='link'><img src={Logo} alt='MacoTech Hotel-Management-Software' className='logo'/></Link>
   <div className='hamburger' onClick={toggleMenu}><RxHamburgerMenu style={{width:'30px' ,height:'30px'}} /></div>
   
   {/* <div className={`overlay ${isOpen ? 'active' : ''}`}> */}
   <nav class={`navbar ${isOpen ? 'active':''}`} >
     <ul class='navbar-list' >
      <li><Link to="/" class='link'>Home</Link></li>
      <li><a href="/#Benefits" class='link'>Benefits</a></li>
      <li><a href="/#Feature" class='link'>Features</a></li>
       
      <li><a href="/#Client" class='link'>Our Client</a></li>
   
      <li><Link to="/blog" class='link'>Blog</Link></li>
      <li><Link to="/contactus" class='link'>Contact us</Link></li>
     </ul>
    </nav>
    {/* </div> */}
   </div>
        </>
    );
}

export default Navbar ;